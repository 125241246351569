@charset "utf-8";
@import "../node_modules/bulma/bulma";

$family-sans-serif: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
$red: rgb(226, 53, 42);
$blue: rgb(0, 81, 175);
$red-opacity: rgba(226, 53, 42, 0.25);

$breakpoint-phone: 768px;
$breakpoint-tablet: 1024px;
$error-border: 1px solid $red;
$error-border-shadow: 0 0 0 0.125em $red-opacity;

body { font-family: $family-sans-serif }
.root { flex-direction: column; align-items: stretch; height: 100vh; overflow: hidden; }
.logo { min-width: 120px; min-height: 47px; padding: 35px 0 0 40px }
button {
  &.signup {
    height: 40px;
    color: $white;
    font-size: 16px;
    background: $red;
    border-radius: 4px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.32px;
    line-height: 24px;
    margin-top: 10px;
    &:hover { color: $white; }
    &:focus { color: $white; }
  }
}
.pair {
  > div.columns { margin-bottom: 0!important;
    > div.column { padding-bottom: 0!important; }
  }
}
.info {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.15px;
  line-height: 20px;
  > a { color: $red; }
  > span { color: $red; }
}
.folder-image { position: absolute; left: -35px; bottom: 161px; }
.ruler-image { position: absolute; left: 185px; top: 240px; }
.patch-image { position: absolute; right: -30px; bottom: 300px; }
.page-image { display: block; margin-left: auto; margin-right: auto; width: 40%; }
.image-appstore { display: block; margin-left: auto; margin-right: auto; width: 30%; }
.subtitle-text { font-size: 18px!important; }
div.field { > label { margin-bottom: 0!important; } margin-bottom: 0.1rem!important; }
div.column { > label { margin-bottom: 0!important; } }
div.hero-body { padding: 0 1.5rem !important; }
p.note {
  font-size: 16px; font-weight: normal; letter-spacing: -0.15px; line-height: 20px; > a { color: $red; }
  margin-bottom: 16px;
  margin-top: 16px;
}
.field.consent-block { margin-top: 7px!important; line-height: 20px; letter-spacing: -0.15px; margin-bottom: 16px !important;}

#iwoc-generic-error {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
}

.warning-message {
  color: rgb(51, 51, 51);
  font-size: 16px;
  font-weight: normal;
  height: 72px;
  letter-spacing: -0.18px;
  line-height: 24px;
  text-align: center;
  background: rgba(226, 53, 42, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(226, 53, 42, 0.3);
  display: flex;
  .info {
    margin: auto;
  }
}

.resend-message {
  a, span {
    color: rgb(188, 188, 188);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 24px;
  }
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
  height: 24px;

  a.disabled {
    cursor: default;
  }
}